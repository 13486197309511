import React, { useState, useEffect, useRef } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { Card, Form, InputGroup, Button, ListGroup, Spinner, Row } from "react-bootstrap";
import { AgentChildComponentProps } from "../../pages/agentHomePage";
import ReactMarkdown from "react-markdown";
import '../markdown/markdown.css'


type AgentChatCardProps = {

} & AgentChildComponentProps


interface ChatMessage {
  timestamp: number;
  text: string;
  is_user: boolean;
}

const AgentChatCard: React.FC<AgentChatCardProps> = (props) => {
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [awaitChat, setAwaitChat] = useState(false);
  const [typingIndicator, setTypingIndicator] = useState("• • • ");

  useEffect(() => {
    const loadData = async() => {
        const chatKey = `chatHistory_${props.agent_id}`;

        // first, see if there is an active conversation at all, if not clear session history
        let is_active = false
        try {
            const activeChat = await props.multiverse.get<any>(`/v2/agents/chat/active?agent_id=${props.agent_id}`)
            if(activeChat.active) {
                is_active = true
                // restore the chat history from session storage
                const savedChat = sessionStorage.getItem(chatKey);
            
                if (savedChat) {
                    setChatHistory(JSON.parse(savedChat)); // ✅ Load chat from session instantly
                }
            }
        } catch (error) {
            console.error("Error checking for active chat:", error);
        }

        if(!is_active) {
            // clear the session storage
            sessionStorage.removeItem(chatKey)
            // else set to await
            setChatHistory([])
            setAwaitChat(true)
        }
    
        fetchChatHistory(); // ✅ Fetch fresh messages from the API regardless (this also initates a new chat)
    }
    loadData()
  }, [props.agent_id]);

  useEffect(() => {
    const chatKey = `chatHistory_${props.agent_id}`; // Unique key for each agent
    sessionStorage.setItem(chatKey, JSON.stringify(chatHistory)); // Save active chat messages
  }, [chatHistory]);
  
  const fetchChatHistory = async () => {
    try {
      const response = await props.multiverse.get<ChatMessage[]>(`/v2/agents/chat/recent?agent_id=${props.agent_id}`); // Fetch chat history
        setChatHistory(response);
        setAwaitChat(false); // Hide typing indicator in case a new chat was initiated
    } catch (error) {
      console.error("Error loading chat history:", error);
    }
  };

  // Scroll to the latest message
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory, props.agent_id]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
  
    const userMessage: ChatMessage = {
      timestamp: getUnixTimestamp(new Date()),
      text: newMessage,
      is_user: true,
    };
  
    // Update UI immediately with user's message
    setChatHistory((prev) => [...prev, userMessage]);
    setNewMessage("");
    setAwaitChat(true); // Show typing indicator
  
    try {
      // Fetch AI response asynchronously
      const body = {
        agent_id: props.agent_id,
        message: newMessage
      }
      const response = await props.multiverse.post<string>(`/v2/agents/chat`, body); 
      
      const agentReply: ChatMessage = {
        timestamp: getUnixTimestamp(new Date()),
        text: response || "No response received.", // Use API response or fallback text
        is_user: false,
      };
  
      // Update UI with AI response
      setChatHistory((prev) => [...prev, agentReply]);
    } catch (error) {
      console.error("Error fetching AI response:", error);
  
      // Add error message to chat
      setChatHistory((prev) => [
        ...prev,
        {
          timestamp: getUnixTimestamp(new Date()),
          text: "Error: AI response failed.",
          is_user: false,
        },
      ]);
    } finally {
        setAwaitChat(false); // Hide typing indicator
    }
  };


    useEffect(() => {
    if (awaitChat) {
        const interval = setInterval(() => {
        setTypingIndicator((prev) =>
            prev === "• • • • • " ? "• " : prev + "• "
        );
        }, 250); // Update every 500ms for smooth animation

        return () => clearInterval(interval); // Cleanup when chat is received
    }
    return undefined;
    }, [awaitChat]);

  const getUnixTimestamp = (date: Date) => Math.floor(date.getTime() / 1000);

  // const formatTime = (timestamp: number) => {
  //   new Date(timestamp * 1000).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: false });
  // }
  const formatTime = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);
    const now = new Date();
  
    const isSameDay = date.toDateString() === now.toDateString();
  
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = date.toDateString() === yesterday.toDateString();
  
    const diffInDays = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
  
    if (isSameDay) {
      return date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: false });
    }
  
    if (isYesterday) {
      return `Yesterday ${date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: false })}`;
    }
  
    if (diffInDays < 7) {
      return date.toLocaleDateString("en-GB", { weekday: "long" }); // e.g. "Wednesday"
    }
  
    return date.toLocaleDateString("en-GB", { day: "2-digit", month: "long" }); // e.g. "14/03"
  };

  return (
    <Card className="mt-2 discovery-content-card" style={{ flexGrow: 1, maxHeight: "60vh", display: "flex", flexDirection: "column" }}>
        <Card.Header className="mb-3">Chat with {props.agent?.display_name}</Card.Header>
        <Card.Body className="d-flex flex-column" style={{ flex: 1, overflow: "hidden" }}>
        
        <div ref={chatContainerRef} style={{ flex: 1, overflowY: "auto", paddingRight: "5px" }}>
          <ListGroup variant="flush">
            {chatHistory.map((msg, index) => (
              <ListGroup.Item
                key={index}
                className={`d-flex ${msg.is_user ? "justify-content-end text-end" : "justify-content-start text-start"}`}
                style={{ 
                  border: "none", 
                  background: "transparent", 
                  padding: "10px 0px"
                }}
              >
                <div 
                  className={`p-2 rounded shadow-sm ${msg.is_user ? "bg-primary text-white" : "bg-light"}`} 
                  style={{ maxWidth: "75%", fontSize: "0.9rem", position: "relative" }}
                >
                  <ReactMarkdown className="react-markdown">{msg.text.trim()}</ReactMarkdown>
                </div>
                <small 
                    className="text-muted position-absolute"
                    style={{ 
                        fontSize: "0.75rem", 
                        opacity: 0.7, 
                        bottom: "-0.7em", /* Moves timestamp lower */
                        right: msg.is_user ? "0" : "auto", /* Aligns to the right for user messages */
                        left: msg.is_user ? "auto" : "0", /* Aligns to the left for agent messages */
                    }}
                    >
                {formatTime(msg.timestamp)}
                </small>
              </ListGroup.Item>
            ))}
            {/* Show typing indicator when awaiting chat */}
            {awaitChat && (
            <ListGroup.Item
                className="d-flex justify-content-start text-start"
                style={{ border: "none", background: "transparent", padding: "5px 10px" }}
            >
                <div 
                className="p-2 rounded shadow-sm bg-light" 
                style={{ maxWidth: "50%", fontSize: "0.9rem", position: "relative" }}
                >
                {typingIndicator}
                </div>
            </ListGroup.Item>
            )}
          </ListGroup>
        </div>
      </Card.Body>
      <Card.Footer>
        <InputGroup className="d-flex align-items-end" > 
        <Form.Control
          as={TextareaAutosize}
            minRows={1}
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e:any) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                if (!awaitChat) handleSendMessage();
              }
            }}
            style={{ resize: 'none', flex: 1 }}
            />
            <Button variant="primary" onClick={handleSendMessage} disabled={awaitChat} style={{
          whiteSpace: "nowrap"  // keep “Send” text on one line
        }}>Send</Button>
        </InputGroup>
      </Card.Footer>
    </Card>
  );
};

export default AgentChatCard;
