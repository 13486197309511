import React, { ChangeEventHandler, ReactNode, useEffect, useState, useRef } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Image, Card, Button, Spinner, Col, Form, Row } from 'react-bootstrap';
import TestBanner from '../../assets/testbanner-grenoble.jpg';
import { IoMdCamera } from 'react-icons/io'
import { RiPencilFill } from 'react-icons/ri'
//import './headercard.css'
import ValidatedField, { VALID_DOMAIN_NAME } from '../validatedField';
import IsDeveloper from '../isDeveloper';
import { set } from 'date-fns';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { AgentChildComponentProps } from '../../pages/agentHomePage/index';

type AgentCraftCardProps = {

} & AgentChildComponentProps

type TGlobalConfig = {
    agent_creation_cost_metacoins?: number,
    agent_recraft_cost_metacoins?: number,
    agent_recraft_faceicon_cost_metacoins?: number,
}

export function _AgentCraftingCard( props: AgentCraftCardProps) {

    const [crafting, setCrafting] = useState<boolean>(false)
    const {refreshInventory} = props.multiverse;
    const [reloadInventoryModules, setReloadInventoryModules] = useState<boolean>(false)
    const [remixFaceIconOnly, setRemixFaceIconOnly] = useState<boolean>(false)
    const [globalConfig, setGlobalConfig] = useState<TGlobalConfig | undefined>(undefined)


    // inventory modules state
    type InventoryModules = {
        _id: string;
        display_name: string;
        cost_mc: number;
        iconblob_url: string;
        modules: { _id: string, display_name:string, count: number }[]
    }[]
    const [inventoryModules, setInventoryModules] = useState<InventoryModules>([])
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    useEffect(() => {
        loadData()
        .then(x => {
        })
    }
    ,[reloadInventoryModules])


    const loadData = async() => {
        const config = await props.multiverse.get<TGlobalConfig>(`/v2/agents/globalconfig`)
        setGlobalConfig(config)
        const res = await props.multiverse.get<InventoryModules>(`/v2/agents/ownedmodules`)
        console.log(res)
        setInventoryModules(res)
        return res;
    }

    // reset page after crafting
    useEffect(() => {
        setSelectedItems([])
        refreshInventory()
        setReloadInventoryModules(!reloadInventoryModules)
        setRemixFaceIconOnly(false)
    }
    ,[props.shouldReload])


    // calculate cost of crafting if selected list changes
    const [craftingCost, setCraftingCost] = useState<number>(0)
    useEffect(() => {
        // calculate cost of crafting, always a cost for creating agent or regenerating without adding modules
        let cost = props.agent_id ? (globalConfig?.agent_recraft_cost_metacoins || 100) : (globalConfig?.agent_creation_cost_metacoins || 500)
        if(remixFaceIconOnly)
            cost = (globalConfig?.agent_recraft_faceicon_cost_metacoins || 100)
        selectedItems.forEach((item_id) => {
            // find the module id in one of the items modules array, and use the items cost
            inventoryModules.forEach((moduletype) => {
                const found = moduletype.modules.find(x => x._id === item_id)
                if(found)
                    cost += moduletype.cost_mc
            })
        })
        setCraftingCost(cost)
    }, [selectedItems, remixFaceIconOnly, globalConfig])

    const canCraft = props.agent_id || selectedItems.length > 0


    // card for showing crafting costs, and button for executing crafting
    const ExecuteCraftingCard =() => {

        const onCraftingButtonClicked = async () => {
            setCrafting(true)
            try {
                let body: any = {
                    agent_id: props.agent_id,
                    modules: selectedItems
                }
                if(remixFaceIconOnly)
                    body.remix_faceicon_only = true
                const res = await props.multiverse.post<any>(`/v2/agents/craft`, body)
                console.log(res)
                setCrafting(false)
                // if new agent, navugate to new page with id, else refresh
                if(!props.agent_id)
                    props.history.push(`/agent/${res._id}`)
                else {
                    alert("Crafting successful!")
                    props.reload()
                }
            } catch(e) {
                alert("Crafting failed:\n" + e)
                setCrafting(false)
            }
        }

        const is_creating = props.agent_id ? false : true
        const is_remix = selectedItems.length > 0 ? false : true
        const action = is_creating ? "creating" : (is_remix ? "remixing" : "crafting")
        const button_action = is_creating ? "Create" : (is_remix ? "Remix" : "Craft")
    
        return ( 
            <Card className="mt-2 discovery-content-card">
                <Card.Header>
                    <div>Agent Crafting</div>
                </Card.Header>
                <Card.Body>


                    {canCraft && <>
                    <p>{is_creating?"Add":"Remix your agent's personality, or add"} more modules from your inventory to customise further.
                    <>{is_remix && <Form.Switch id="showUnowned" label="Remix Profile Image Only" checked={remixFaceIconOnly} onChange={(e:any) => setRemixFaceIconOnly(e.target.checked)}/>}</>
                    </p>
                    <p>Your cost of {action} will be <b>{craftingCost}mc.</b></p>
                    {!crafting && <Button variant="primary" style={{width:'100%'}} onClick={() => onCraftingButtonClicked() }>{button_action} Now!</Button>}
                    {crafting && <Button variant="primary" disabled={true} style={{width:'100%'}}><Spinner animation="border" /></Button>}</>}
                    {!canCraft &&
                     <><p>Add modules from your inventory to create this agent.</p>
                     <Button variant="primary" style={{width:'100%'}} disabled={true}>Create Now!</Button></>}
                </Card.Body>
            </Card>
        )
    }
    
    // card for being able to purchase card packs for crafting
    const PackPurchasingCard = () => {

        const [purchasing, setPurchasing] = useState<boolean>(false)
        const [module_packs, setModulePacks] = useState<{count: number, cost_mc: number}[]>([])

        // get module packs from server
        useEffect(() => {
            const loadData = async() => {
                const res = await props.multiverse.get<{count: number, cost_mc: number}[]>(`/v2/agents/modulepacks`)
                console.log(res)
                setModulePacks(res)
            }
            loadData()
        }, [])
            
        const onPurchasingButtonClicked = async (count: number) => {
            setPurchasing(true)
            try {
                const res = await props.multiverse.get<any>(`/v2/agents/purchasemodulepack?modulecount=${count}`)
                console.log(res)
                refreshInventory()
                setReloadInventoryModules(!reloadInventoryModules)
            } catch(e) {
                alert("Purchasing failed:\n" + e)
            }
            setPurchasing(false)
        }
    
        return ( 
            <Card className="mt-2 discovery-content-card">
                <Card.Header>
                    <div>Purchase Agent Module Packs</div>
                </Card.Header>
                <Card.Body>
                    <p>Choose from the following module packs:</p>
                    {module_packs.map((pack) => {
                        return <Form.Group as={Row} className="mb-3 align-items-center">
                            <Col sm={2}>
                            {!purchasing && <Button variant="primary" style={{width:'100%'}} onClick={() => onPurchasingButtonClicked(pack.count)}>Buy</Button>}
                            {purchasing && <Button variant="primary" style={{width:'100%'}} disabled={true}><Spinner animation="border" /></Button>}
                            </Col>
                            <Col>
                                <Form.Label>{`Pack of ${pack.count} modules for ${pack.cost_mc}mc`}</Form.Label>
                            </Col>
                        </Form.Group>
                    })}
                </Card.Body>
            </Card>
        )
    }
    
    
    // card for selecting inventory modules to use in crafting
    const AgentUserInventoryCard = () => {

        // to ensure we don't reset scroll positions when selecting/deselecting items
        const scrollRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

        const onInventoryItemClicked = (item_id:string) => {
            // add or remove item from selectedItems
            if(selectedItems.includes(item_id)) {
                setSelectedItems(selectedItems.filter(x => x !== item_id))
            } else {
                setSelectedItems([...selectedItems, item_id])
            }
            // ensure remix face icon only is off
            setRemixFaceIconOnly(false)
        }

        const SeletableItems = () => {
            return (
                <div style={{ maxHeight: "800px", overflowY: "auto", overflowX: "hidden" }}>
                    {inventoryModules.map((item) => (
                        <div key={item._id} className="mb-3">
                            {/* Sub-header for module type */}
                            <h5 className="mb-2">{item.display_name}</h5>
                            
                            {/* Horizontally scrolling list */}
                            <div style={{ display: "flex", overflowX: "auto", gap: "10px", paddingBottom: "10px" }}>
                                {item.modules.map((module) => (
                                    <Button
                                        key={module._id}
                                        onClick={() => onInventoryItemClicked(module._id)}
                                        variant="light"
                                        className="p-2 text-center d-flex flex-column align-items-center position-relative"
                                        style={{ width: "100px", flexShrink: 0 }}>
                                        <Image src={item.iconblob_url} rounded style={{ width: "80px", height: "80px" }} />
                                        <small>{module.display_name}</small>
                                        
                                        {/* Check overlay for selected items */}
                                        {selectedItems.includes(module._id) && (
                                            <div style={{ position: "absolute", top: "8px", right: "8px", width: "16px", height: "16px", fontSize: "2em", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                &#9989;
                                            </div>
                                        )}
                                        
                                        {/* Count badge */}
                                        {module.count > 1 && (
                                            <div style={{ position: "absolute", top: "0", left: "8px", width: "20px", height: "20px", color: "white", backgroundColor: "royalblue", borderRadius: "50%", fontSize: "0.8em", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                {module.count}
                                            </div>
                                        )}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            );
        };
            
        return ( 
        <Card className="mt-2 discovery-content-card">
            <Card.Header>
                <div>Customise Your Agent By Adding Modules</div>
            </Card.Header>
            <Card.Body>
                <SeletableItems />
            </Card.Body>
        </Card>
        )
    }

    return ( 
        <>
        <ExecuteCraftingCard />
        <AgentUserInventoryCard />
        <PackPurchasingCard />
        </>
    )
}
export const AgentCraftingCard = withMultiverseApi(_AgentCraftingCard);

export default AgentCraftingCard;
